$baseUrl: '/__app__/id/dev';
@use '@smart/web-components/dist/scss/variables' as *;

.errorcodenotification {
  padding-bottom: var(--spacing-500);
  min-height: var(--spacing-900);
  p {
    font-size: 16px !important;
  }
  span {
    font-size: 16px !important;
  }
}

// custom class for notification componente
.notification_width {
  width: 100%;
}
