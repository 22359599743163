$baseUrl: '/__app__/id/dev';
@use '@smart/web-components/dist/scss/variables' as *;

.register {
  width: 100%;
  flex: 1;
  background-color: var(--bg-level-2);

  @include breakpoint(l) {
    background-color: var(--bg-level-1);
  }

  &__left {
    width: 100%;
    background-color: var(--bg-level-1);
    padding: 0 var(--col-1);
    height: fit-content;

    @include breakpoint(l) {
      width: 50%;
      height: 100%;
      padding-left: var(--col-1);
    }
  }

  &__right {
    display: none;
    width: 50%;
    height: 100%;
    background-color: var(--bg-level-2);

    @include breakpoint(l) {
      display: block;
    }
  }

  // modify toast style
  ol {
    top: 0;
    padding-top: var(--spacing-800);
    padding-right: var(--col-1);

    div {
      background-color: var(--sig-success);
      color: var(--c-inverted);
    }

    @include breakpoint(l) {
      padding-top: var(--col-1);
      padding-top: var(--col-1);
    }
  }
}
