$baseUrl: '/__app__/id/dev';
@use '@smart/web-components/dist/scss/_variables.scss' as *;

@mixin use-underline {
  cursor: pointer;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
}

.email-verification {
  width: var(--col-14);
  padding-top: 58px;
  margin-bottom: 43px;

  &--invalid {
    margin-bottom: 15px;
  }

  @include breakpoint(m) {
    width: var(--col-8);
  }

  @include breakpoint(l) {
    padding-top: 105px;
    margin-bottom: 0;
  }

  &__title {
    margin-bottom: var(--spacing-400);
    color: var(--primary-onyx-grey, #121212);
  }

  &__subtitle {
    margin-bottom: var(--spacing-100);
    color: var(--c-high-contrast);
  }

  &__email {
    color: var(--c-high-contrast);
    margin-bottom: var(--spacing-400);

    @include breakpoint(l) {
      margin-bottom: var(--spacing-450);
    }
  }

  &__edit {
    margin-left: var(--spacing-100);
    @include use-underline;
  }

  &__input {
    @include text-type(CAP-100-fixed);
    width: calc((var(--col-14) - var(--spacing-100) * 5) / 6) !important;
    height: 52px;
    border-bottom: 1px solid var(--c-mid-contrast);

    -moz-appearance: textfield;
    -ms-appearance: textfield;
    appearance: textfield;

    &--invalid {
      border-color: var(--sig-error);
      color: var(--sig-error);
    }

    @include breakpoint(l) {
      width: calc((var(--col-8) - var(--spacing-100) * 5) / 6) !important;
    }
  }

  &__input::-webkit-outer-spin-button,
  &__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    color: var(--c-mid-contrast);
  }
  ::-ms-input-placeholder {
    color: var(--c-mid-contrast);
  }

  &__resend {
    @include use-underline;
    color: var(--c-high-contrast);
    margin: var(--spacing-400) 0;

    @include breakpoint(l) {
      margin: var(--spacing-450) 0;
    }
  }
}
