$baseUrl: '/__app__/id/dev';
@mixin use-content-styles {
  min-height: calc(60vh);
  height: auto;
  padding-top: var(--spacing-900);
  padding-bottom: var(--spacing-1000);
  padding-left: var(--spacing-100) !important;
  padding-right: var(--spacing-100) !important;
  margin-left: var(--spacing-100) !important;
  margin-right: var(--spacing-100) !important;
}

.smart-id {
  &__wrapper {
    max-width: 475px;
    margin: 0 auto;
  }

  &__content {
    @include use-content-styles;
    &--center {
      @include use-content-styles;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
