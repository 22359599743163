$baseUrl: '/__app__/id/dev';
@use '@smart/web-components/dist/scss/variables' as *;

.email-entry {
  width: var(--col-14);
  padding-top: 58px;
  margin-bottom: 68px;

  @include breakpoint(m) {
    width: var(--col-8);
  }

  @include breakpoint(l) {
    padding-top: 105px;
  }

  &__title {
    margin-bottom: var(--spacing-400);
    color: var(--primary-onyx-grey, #121212);
  }

  &__subtitle {
    margin-bottom: var(--spacing-400);
    color: var(--c-high-contrast);

    @include breakpoint(l) {
      margin-bottom: var(--spacing-450);
    }
  }

  &__invalid {
    input {
      color: var(--sig-error);
    }
  }

  &__button {
    margin-top: var(--spacing-400);

    @include breakpoint(l) {
      margin-top: var(--spacing-450);
    }
  }
}
