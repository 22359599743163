$baseUrl: '/__app__/id/dev';
@mixin use-content-styles-v2 {
  min-height: calc(70vh);
  height: auto;
  display: flex;
}

.layout {
  &__content {
    @include use-content-styles-v2;

    &--center {
      @include use-content-styles-v2;
      justify-content: center;
      align-items: center;
    }
  }
}
