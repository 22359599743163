body {
  background-color: var(--bg-level-3);
  background: url(assets/bg-03-mobile.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  @include breakpoint(m) {
    background: url(assets/bg-02.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}

.gigya-screen * {
  color: var(--c-high-contrast) !important;
}

#container {
  max-width: 475px;
  margin: 0 auto;
}

#container_content {
  max-width: 475px;
  margin: 0 auto;
}

#container_content {
  max-width: 100% !important;
  font-size: 16px;
}

.gigya-screen.portrait.mobile,
.gigya-screen.portrait {
  width: 100% !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.gigya-screen .gigya-layout-row.with-divider > .gigya-layout-cell {
  min-width: 100% !important;
  width: 100% !important;
}

.gigya-screen-content div.gigya-screen.landscape {
  padding-left: 0px !important;
  padding-right: 0px !important;
  width: 100% !important;
}

.gigya-screen * {
  font-family: var(--font-family-sans) !important;
}

.gigya-screen-content div.gigya-screen.landscape {
  max-width: 475px !important;
  margin: auto !important;
  padding: 0 !important;
}

.register,
.login,
.forgot-password,
.reset-password,
.change-smart-id {
  h1.gigya-screen-caption {
    display: none;
  }
}

.gigya-screen h2 {
  text-align: center !important;
}

.gigya-screen.portrait.mobile h2 {
  font-size: var(--headline-200) !important;
}

.smart-id {
  .gigya-composite-control-header {
    font-size: var(--headline-100) !important;
    font-family: var(--font-family-sans);
    color: var(--c-high-contrast) !important;
    line-height: 110% !important;
    font-weight: var(--font-weight-bold) !important;
  }

  .gigya-composite-control-header {
    b {
      font-size: var(--headline-100);
      font-family: var(--font-family-sans);
      color: var(--c-high-contrast);
      line-height: normal;
    }
  }

  .gigya-error-msg.gigya-error-msg-active {
    text-align: left !important;
    padding-top: 0px !important;
    padding-left: 10px !important;
    padding-bottom: 0 !important;
    color: var(--sig-error) !important;
    font-family: var(--font-family-sans) !important;
    font-size: var(--font-size-caption50) !important;
    line-height: 140% !important;
    font-weight: var(--font-weight-regular) !important;
    letter-spacing: 0 !important;
  }

  .gigya-screen.portrait
    div.gigya-tfa
    .gig-tfa-container
    input.gigya-error.gig-tfa-code-textbox,
  .gigya-screen input.gigya-input-password.gigya-error,
  .gigya-screen input.gigya-input-text.gigya-error,
  .gigya-screen select.gigya-country-code-select.gigya-error,
  select.gigya-error {
    border-radius: 0 !important;
    border-style: solid;
    border-width: 1px;
    border-color: var(--sig-error);
  }

  .gigya-composite-control.gigya-composite-control-dropdown,
  .gigya-composite-control.gigya-composite-control-fieldset,
  .gigya-composite-control.gigya-composite-control-password,
  .gigya-composite-control.gigya-composite-control-textbox {
    padding-top: var(--spacing-400) !important;
    padding-bottom: 0 !important;
  }

  .gigya-composite-control.gigya-composite-control-dropdown input,
  .gigya-composite-control.gigya-composite-control-dropdown select,
  .gigya-composite-control.gigya-composite-control-fieldset input,
  .gigya-composite-control.gigya-composite-control-fieldset select,
  .gigya-composite-control.gigya-composite-control-password input,
  .gigya-composite-control.gigya-composite-control-password select,
  .gigya-composite-control.gigya-composite-control-textbox input,
  .gigya-composite-control.gigya-composite-control-textbox select {
    margin-bottom: 0 !important;
    background-color: transparent !important;
    border: none;
    border-bottom: 1px solid var(--c-mid-contrast) !important;
    color: var(--c-mid-contrast) !important;
    border-radius: 0;
    box-shadow: none;
    font-size: 16px !important;
  }

  .gigya-composite-control.gigya-composite-control-dropdown select.gigya-error {
    border-radius: 0 !important;
    border-style: solid;
    border-width: 1px;
    border-color: var(--sig-error);
  }
}

.login {
  .gigya-composite-control.gigya-composite-control-link {
    padding-left: 0 !important;
    margin-left: var(--spacing-50) !important;
    padding-bottom: var(--spacing-50);
    color: var(--c-high-contrast) !important;
    font-size: var(--link-100-fixed) !important;
    font-weight: var(--font-weight-bold) !important;
    text-decoration: none !important;
    font-family: var(--font-family-sans) !important;
    border-bottom: 2px solid black !important;
  }

  .gigya-input-submit {
    margin-top: var(--spacing-800);
    margin-bottom: var(--spacing-600);
  }

  .gigya-layout-cell {
    text-align: left !important;
  }

  .gigya-composite-control.gigya-composite-control-link {
    margin-left: var(--spacing-50) !important;
  }

  .gigya-composite-control.gigya-composite-control-header {
    padding-bottom: var(--spacing-300) !important;
  }

  .gigya-composite-control.gigya-composite-control-password {
    margin-bottom: var(--spacing-500);
  }

  .portrait.mobile {
    h2 {
      display: block;
      font-family: var(--font-family-sans) !important;
      font-size: var(--headline-200) !important;
      line-height: 110%;
      font-weight: var(--font-weight-bold) !important;
      letter-spacing: -0.01em;
      color: var(--c-high-contrast);
    }
  }

  .gigya-screen {
    h2 {
      text-align: center !important;
    }
  }
}

.login {
  .responsive.with-divider {
    display: none !important;
  }

  .gigya-screen
    .gigya-composite-control.gigya-composite-control-checkbox
    .gigya-label {
    flex: 0 1 auto;
  }
}

.login,
.register,
.reset-password,
.forgot-password {
  .gigya-composite-control.gigya-composite-control-submit {
    text-align: center !important;
  }

  input[type='submit'] {
    height: auto !important;
    padding: var(--spacing-300) 0 !important;
    background: var(--s-primary) !important;
    border-radius: 999px !important;
    font-family: var(--font-family-sans) !important;
    font-size: var(--link-100) !important;
    font-weight: var(--font-weight-bold) !important;
    width: 100% !important;
    margin-top: var(--spacing-700) !important;
    margin-bottom: var(--spacing-200) !important;
    color: var(--c-inverted) !important;
    @include breakpoint(m) {
      width: 70% !important;
    }
  }

  .gigya-composite-control.gigya-composite-control-submit,
  .gigya-composite-control.gigya-composite-control-submit
    + .gigya-error-display-active {
    padding-bottom: 0;
  }

  .gigya-screen .gigya-composite-control.gigya-composite-control-submit,
  .gigya-screen
    .gigya-composite-control.gigya-composite-control-submit
    + .gigya-error-display-active {
    padding-bottom: 0 !important;
  }
}

.login {
  input[type='submit'] {
    margin-bottom: var(--spacing-400) !important;
  }
}

.register {
  .gigya-composite-control.gigya-composite-control-label {
    display: block !important;
  }

  .responsive.with-social-login {
    display: none !important;
  }

  .gigya-composite-control.gigya-composite-control-subscription-widget {
    padding-top: var(--spacing-500) !important;
    padding-bottom: var(--spacing-400) !important;
  }

  .business-switch {
    padding-bottom: var(--spacing-100) !important;
    display: flex !important;
    .gigya-label {
      display: none !important;
    }
    .gigya-error-msg {
      display: none !important;
    }
    .gigya-multi-choice-item {
      padding-top: 0 !important;
      margin-left: 0 !important;
      margin-right: var(--spacing-600) !important;
    }
  }
}

.register,
.reset-password,
.forgot-password {
  .gigya-composite-control-label a {
    padding-left: 0 !important;
    // margin-left: var(--spacing-50) !important;
    padding-bottom: var(--spacing-50);
    color: var(--c-high-contrast) !important;
    font-size: 16px !important;
    font-weight: var(--font-weight-bold) !important;
    text-decoration: none !important;
    font-family: var(--font-family-sans) !important;
    // border-bottom: 2px solid black !important;
    text-decoration: underline !important;
  }

  a.gigya-composite-control-link {
    color: var(--c-high-contrast) !important;
    font-family: var(--font-family-sans) !important;
    font-size: var(--font-size-link100) !important;
    line-height: 130% !important;
    font-weight: var(--font-weight-bold) !important;
    letter-spacing: 0.02em !important;
    position: relative !important;
    box-sizing: border-box !important;
    display: flex !important;
    border-width: 1px !important;
    border-style: none !important;
    align-items: center !important;
    justify-content: center !important;
    cursor: pointer !important;
    display: inline-flex !important;
    border-radius: 999px !important;
    padding: var(--spacing-300) 0 !important;
    text-decoration: none !important;
    width: 100% !important;
    &:hover {
      background: var(--sl-hover);
    }
    @include breakpoint(m) {
      width: 70% !important;
    }
  }

  .gigya-screen.portrait
    .gigya-layout-row.with-divider
    > .gigya-layout-cell.with-divider {
    height: auto !important;
    background-repeat: repeat-x;
    position: relative;
  }

  .gigya-screen.landscape
    .gigya-layout-row.with-divider
    .gigya-composite-control.gigya-composite-control-submit {
    padding-bottom: 0;
    display: flex !important;
  }
}

.gigya-screen.portrait
  .gigya-layout-row.with-divider
  > .gigya-layout-cell.with-divider {
  height: 81px;
  background-repeat: repeat-x;
  position: relative;
}

.forgot-password {
  .gigya-composite-control-submit {
    padding-bottom: var(--spacing-100) !important;
    text-align: center !important;
  }
}

#gigya-register-screen
  .gigya-composite-control.gigya-composite-control-label.smart-id-input-info-label-b2c-screen {
  position: relative;
  display: block;
  margin-top: 5px !important;
  font-weight: 400;
  font-size: 13px !important;
  text-align: left;
  margin-left: 10px;
  padding-left: 16px;
  padding-bottom: 0px !important;
  line-height: 22px !important;
  color: var(--c-mid-contrast) !important;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 5px;
    height: 12px;
    width: 12px;
    margin-right: var(--spacing-100);
    background-size: 12px 12px;
    background-image: url(assets/0c0a9f9e030f38dc5027.svg);
    background-repeat: no-repeat;
  }
}

.forgot-password {
  .gigya-composite-control.gigya-composite-control-submit.submit-wrapper {
    padding-top: 0px !important;
  }
  .main-text.head {
    padding-bottom: 40px;
    display: block;
    font-family: var(--font-family-sans);
    line-height: 110%;
    font-weight: var(--font-weight-regular);
    letter-spacing: -0.01em;
    color: var(--c-high-contrast);
  }
}

.reset-password {
  .gigya-composite-control-submit {
    padding-top: 0px !important;
  }

  h2 {
    padding-bottom: 40px;
    display: block;
    font-family: var(--font-family-sans);
    font-size: var(--headline-200);
    line-height: 110%;
    font-weight: var(--font-weight-bold) !important;
    letter-spacing: -0.01em;
    color: var(--c-high-contrast);
  }
}

input[type='button'] {
  cursor: pointer;
  width: 100%;
  border-color: transparent !important;
  box-shadow: none !important;
  -ms-flex-line-pack: center;
  align-content: center;
  text-align: center;
  font-size: 19px;
  padding-left: 0 !important;
  width: 100% !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  margin-left: 0px !important;
  background-color: var(--c-high-contrast) !important;
  border: 0 solid transparent !important;
  color: var(--c-inverted) !important;
  border-radius: 999px !important;
  font-family: var(--font-family-sans) !important;
  font-size: var(--link-100) !important;
  font-weight: var(--font-weight-bold) !important;
  text-align: center !important;
  @include breakpoint(m) {
    width: 70% !important;
  }
}

.gigya-screen.landscape
  .gigya-layout-row.with-divider
  > .gigya-layout-cell.with-site-login,
.gigya-screen.landscape
  .gigya-layout-row.with-divider
  > .gigya-layout-cell.with-social-login {
  min-width: 0;
  width: 100% !important;
}

.gigya-checkbox-text {
  font-size: 16px !important;
}

.forgot-password {
  .gigya-layout-row:nth-child(1) {
    text-align: left !important;
    label {
      font-size: var(--font-size-paragraph100) !important;
      padding-bottom: var(--spacing-300) !important;
    }
    .gigya-screen.portrait.mobile .gigya-composite-control-label {
      text-align: left !important;
      font-size: var(--font-size-paragraph100) !important;
    }
    .portrait.mobile .gigya-composite-control-label {
      font-size: var(--font-size-paragraph100) !important;
    }
  }
}

.gigya-composite-control.gigya-composite-control-header {
  padding-bottom: var(--spacing-600) !important;
}

.gigya-screen.landscape
  .gigya-layout-row.with-divider
  > .gigya-layout-cell.with-divider {
  margin-top: 0px !important;
}

.gigya-verification-sent-screen {
  .gigya-composite-control.gigya-composite-control-link.gigya-button-link {
    padding-bottom: 0 !important;
    margin-top: var(--spacing-600) !important;
    display: inline-block !important;
  }
}

.gigya-forgot-password-success-screen {
  .gigya-composite-control.gigya-composite-control-link.gigya-button-link {
    display: inline-block !important;
  }
}

.register {
  .gigya-composite-control.gigya-composite-control-dropdown select {
    padding-left: 8px;
  }

  .gigya-composite-control .gigya-composite-control-dropdown + .gigya-label {
    display: none !important;
    visibility: visible !important;
  }
}

.gigya-composite-control.gigya-composite-control-dropdown {
  label {
    display: none;
  }
}

.login {
  .gigya-composite-control {
    padding-bottom: 0px !important;
  }
}

.register {
  .gigya-composite-control-phone-number-input
    input.gigya-subscriber-phone-number-input {
    display: inline-block;
    width: calc(100% - 159px) !important;
    margin: 0;
  }

  .gigya-composite-control-phone-number-input select.gigya-country-code-select {
    display: inline-block;
    width: 145px !important;
    margin: 0;
    margin-right: 10px !important;
  }
  .smart-id-info-label {
    font-size: 16px !important;
    padding: 0 !important;
    line-height: 32px !important;
    margin-bottom: var(--spacing-500);
  }
  .gigya-composite-control-label {
    span {
      font-size: 16px !important;
      line-height: 140% !important;
    }

    a {
      line-height: 140% !important;
    }
  }
  .gigya-screen.portrait.mobile .gigya-composite-control-label {
    font-size: 16px;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.gigya-verification-sent-screen,
.gigya-forgot-password-success-screen,
.gigya-reset-password-success-screen,
.gigya-verification-pending-screen,
.gigya-complete-consent-screen,
.gigya-complete-registration-screen {
  h1 {
    display: inline-block !important;
  }
  .gigya-composite-control.gigya-composite-control-label {
    font-size: var(--font-size-paragraph100) !important;
    line-height: 140% !important;
    text-align: left !important;
  }
  .gigya-composite-control-link.gigya-button-link {
    margin-top: var(--spacing-600) !important;
    padding-bottom: 3px !important;
  }

  .gigya-composite-control-link {
    margin-top: var(--spacing-600) !important;
  }

  .main-text.gigya-message {
    font-size: var(--font-size-paragraph100) !important;
    text-align: left !important;
  }
}

.gigya-complete-consent-screen {
  .gigya-composite-control.gigya-composite-control-label {
    padding-bottom: var(--spacing-400) !important;
  }
}

.gigya-complete-registration-screen,
.gigya-complete-consent-screen {
  .gigya-layout-row:nth-child(1) {
    padding-bottom: var(--spacing-400);
  }
}

.login {
  .gigya-composite-control-label {
    font-size: 16px !important;
  }
}

.gigya-composite-control.gigya-composite-control-label.main-text {
  font-size: var(--font-size-paragraph100) !important;
  text-align: left !important;
}

.register {
  .landscape .gigya-layout-row.with-divider > .gigya-layout-cell.with-divider {
    margin-top: 0px !important;
  }
  .smart-id-input-info-label-b2b-screen {
    position: relative;
    display: inline-block !important;
    margin-top: 5px;
    font-weight: 400;
    font-size: 13px !important;
    text-align: left;
    margin-left: 10px;
    padding-left: 16px !important;
    padding-bottom: 0px !important;
    line-height: 22px !important;
    color: var(--c-mid-contrast) !important;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 5px;
      height: 12px;
      width: 12px;
      margin-right: var(--spacing-100);
      background-size: 12px 12px;
      background-image: url(assets/0c0a9f9e030f38dc5027.svg);
      background-repeat: no-repeat;
    }
  }
  .smart-id-input-info-label-b2b-screen-vat {
    position: relative;
    display: inline-block !important;
    margin-top: 5px;
    font-weight: 400;
    font-size: 13px !important;
    text-align: left;
    margin-left: 10px;
    padding-left: 16px !important;
    padding-bottom: 0px !important;
    line-height: 22px !important;
    color: var(--c-mid-contrast) !important;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 5px;
      height: 12px;
      width: 12px;
      margin-right: var(--spacing-100);
      background-size: 12px 12px;
      background-image: url(assets/0c0a9f9e030f38dc5027.svg);
      background-repeat: no-repeat;
    }
  }
}

.gigya-reset-password-success-screen {
  .main-text {
    padding-bottom: 0px !important;
  }

  a,
  .gigya-composite-control.gigya-composite-control-link.gigya-button-link {
    position: relative !important;
    box-sizing: border-box !important;
    display: flex !important;
    border-width: 1px !important;
    border-style: solid !important;
    align-items: center;
    justify-content: center !important;
    cursor: pointer !important;
    font-family: var(--font-family-sans) !important;
    font-size: var(--font-size-link100) !important;
    line-height: 130% !important;
    font-weight: var(--font-weight-bold) !important;
    letter-spacing: 0.02em !important;
    border-radius: 999px !important;
    padding: var(--spacing-300) 0 !important;
    &:hover {
      background: var(--sl-hover);
    }
  }
  input[type='button'] {
    margin: calc(-1 * var(--spacing-300)) 0;
    width: 100% !important;
  }
}

.gigya-screen.portrait
  div.gigya-tfa
  .gig-tfa-container
  .gig-tfa-code-remember-checkbox,
.gigya-screen input[type='checkbox'],
.gigya-screen input[type='radio'] {
  scale: 1.5;
}

#gigya-org-register-success-screen {
  .gigya-composite-control.gigya-composite-control-label {
    text-align: left !important;
    line-height: 140% !important;
    font-size: var(--font-size-paragraph100) !important;
  }
}

.gigya-style-modern {
  * {
    background-color: transparent !important;
  }
}
.gigya-complete-registration-screen,
.gigya-complete-consent-screen {
  h1 {
    display: none !important;
  }

  .gigya-composite-control.gigya-composite-control-label.main-text {
    text-align: left !important;
    font-size: var(--font-size-paragraph100) !important;
    padding-bottom: var(--spacing-500) !important;
  }

  .gigya-composite-control-label {
    span {
      font-size: 16px !important;
      line-height: 140% !important;
    }

    a {
      line-height: 140% !important;
    }
  }

  .gigya-layout-row {
    label.gigya-composite-control.gigya-composite-control-label {
      font-size: 16px !important;
    }
  }

  .gigya-label-text.gigya-composite-control.gigya-composite-control-label.main-text {
    font-size: var(--font-size-paragraph100) !important;
    padding-top: var(--spacing-400) !important;
  }

  span.gigya-label-text {
    font-size: 16px !important;
    width: 90% !important;
    @include breakpoint(s) {
      width: 70%;
    }
  }

  .gigya-composite-control-label a,
  .gigya-complete-consent-screen .gigya-composite-control-label a,
  .reset-password .gigya-composite-control-label a,
  .forgot-password .gigya-composite-control-label a {
    padding-left: 0 !important;
    padding-bottom: var(--spacing-50);
    color: var(--c-high-contrast) !important;
    font-size: 16px !important;
    font-weight: var(--font-weight-bold) !important;
    text-decoration: none !important;
    font-family: var(--font-family-sans) !important;
    text-decoration: underline !important;
  }

  .gigya-composite-control.gigya-composite-control-checkbox .gigya-label {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    margin-left: 8px;
    max-width: 95% !important;
    display: block !important;
    padding-bottom: var(--spacing-400) !important;
  }
}

.register {
  .custom-email-error {
    text-align: left !important;
    padding-top: 0px !important;
    padding-left: 10px !important;
    padding-bottom: 0 !important;
    color: var(--sig-error) !important;
    font-family: var(--font-family-sans) !important;
    font-size: var(--font-size-caption50) !important;
    line-height: 140% !important;
    font-weight: var(--font-weight-regular) !important;
    letter-spacing: 0 !important;
  }
}

.change-smart-id {
  .gigya-composite-control.gigya-composite-control-submit {
    display: flex !important;
    justify-content: center !important;
  }

  .gigya-composite-control-label {
    font-size: var(--font-size-paragraph100) !important;
    padding-bottom: var(--spacing-300) !important;
    font-weight: normal !important;
  }

  input[type='submit'] {
    height: auto !important;
    padding: var(--spacing-300) 0 !important;
    background: var(--s-primary) !important;
    border-radius: 999px !important;
    font-family: var(--font-family-sans) !important;
    font-size: var(--link-100) !important;
    font-weight: var(--font-weight-bold) !important;
    width: 100% !important;
    margin-top: var(--spacing-700) !important;
    margin-bottom: var(--spacing-200) !important;
    color: var(--c-inverted) !important;
    @include breakpoint(m) {
      width: 70% !important;
    }
  }
}

input:focus {
  border: 1px solid #2378cd !important;
  outline: none !important;
  box-shadow: 0 0 5px 0 #0070f0 !important;
}

.register {
  .gigya-composite-control.gigya-composite-control-label {
    line-height: 140%;
  }
}

.login,
.register {
  .subs-widget-container {
    display: flex;
    .subscription-checkbox {
      align-self: flex-start;
      margin: 5px 0 0 0;
    }

    .subscription-name-label {
      margin-left: 7px;
      width: 100%;
      line-height: 140%;

      a {
        font-size: 16px;
        font-weight: var(--font-weight-bold);
        text-decoration: underline;
      }

      span {
        font-size: 16px;
        line-height: 140%;
      }
    }
  }
}

// Override Reset Styles applied by ComponentProvider from @smart/react-components
.gigya-input-radio {
  appearance: auto !important;
  -webkit-appearance: auto !important;
  -moz-appearance: auto !important;
  -ms-appearance: auto !important;
  -o-appearance: auto !important;
}

.gigya-input-checkbox {
  appearance: auto !important;
  -webkit-appearance: auto !important;
  -moz-appearance: auto !important;
  -ms-appearance: auto !important;
  -o-appearance: auto !important;
}

fieldset[type='gigya-phone-number-input'] {
  display: flex;
}

.gigya-country-code-select {
  align-items: center;
  appearance: auto !important;
  -webkit-appearance: auto !important;
  -moz-appearance: auto !important;
  -ms-appearance: auto !important;
  -o-appearance: auto !important;
}
