$baseUrl: '/__app__/id/dev';
.conde-input {
  &__warn {
    color: var(--sig-error);
    display: flex;

    align-items: center;
    margin-top: var(--spacing-200);
    padding-left: var(--spacing-100);
  }
}
