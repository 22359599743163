$baseUrl: '/__app__/id/dev';
@use '@smart/web-components/dist/scss/variables' as *;

.personal-details {
  padding: 58px 0 67px;

  @include breakpoint(l) {
    padding: 77px 0 175px;
  }

  &__title {
    color: var(--primary-onyx-grey, #121212);
    margin-bottom: var(--spacing-400);
  }

  &__email {
    margin-bottom: var(--spacing-450);

    &-label {
      margin-bottom: 6px;
      color: var(--c-mid-contrast);
    }

    &-value {
      color: var(--c-high-contrast);
    }

    &-edit {
      color: var(--c-high-contrast);
      margin-left: var(--spacing-100);
      cursor: pointer;
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-skip-ink: none;
      text-decoration-thickness: auto;
      text-underline-offset: auto;
      text-underline-position: from-font;
    }
  }

  &__name {
    div {
      @include breakpoint(l) {
        flex: 1;
      }
    }
  }

  &__name-firstName,
  &__name-lastName {
    width: 100%;

    @include breakpoint(l) {
      min-width: auto;
    }
  }

  &__phone-code {
    width: 101px;
    @include breakpoint(l) {
      width: 144px;
    }
  }

  &__phone-number {
    flex: 1;
  }

  &__icon {
    min-width: initial;
    min-height: initial;
    width: 16px;
    height: 16px;
    border: none;
  }

  &__checkbox {
    margin: var(--spacing-450) 0;
  }

  &__button {
    width: fit-content;
  }

  &__consent {
    padding: 15px 0 0 16px;
    border-radius: 5px;
    border: 1px solid var(--div-default);
    min-height: 75px;
    font-size: 12px;
    margin-bottom: var(--spacing-450);

    div {
      padding-right: 7px;
    }

    textarea {
      max-height: 75px;
      padding: 0;
      padding-right: 8px;
    }

    ::-webkit-scrollbar-track {
      background: #fff;
    }
    ::-webkit-scrollbar {
      width: 4px;
      height: 42px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 99px;
      background: #d9d9d9;
    }

    @include breakpoint(l) {
      textarea {
        padding-right: 21px;
      }
    }
  }
}
