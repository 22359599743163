$baseUrl: '/__app__/id/dev';
@use '@smart/web-components/dist/scss/variables' as *;

.header {
  position: relative;
  height: 64px;
  display: flex;
  justify-content: center;
  background-color: white;
  border-bottom: 1px solid var(--div-default);

  @include breakpoint(l) {
    height: 80px;
  }

  &__back {
    position: absolute;
    left: 24px;
    top: 50%;
    transform: translateY(-50%);

    @include breakpoint(l) {
      left: 60px;
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }

  &__text {
    display: none;

    @include breakpoint(l) {
      display: block;
    }
  }

  &__link {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  &__logo {
    height: 100%;
    color: black;
  }
}
